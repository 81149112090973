import "../styles/App.scss";
import "../styles/index.scss";
import "rc-drawer/assets/index.css";
import "react-tippy/dist/tippy.css";
import "react-multi-carousel/lib/styles.css";
import "react-vertical-timeline-component/style.min.css";
import "rc-pagination/assets/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";

import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import type { ReactElement, ReactNode } from "react";
import { CitiesStateProvider } from "../utils/CitiesContext";
import { NextPage } from "next";
import Script from "next/script";
import Head from "next/head";
import parse from "html-react-parser";
import { get } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { pageview } from "../utils/gtmEvents";

type NextPageWithLayout = NextPage & {
  getLayout?: (pageProps: AppProps, page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): ReactElement {
  const router = useRouter();
  const posId = process.env.NEXT_PUBLIC_PAYU_POS_ID;
  const posKey = process.env.NEXT_PUBLIC_PAYU_POS_KEY;
  const ueApiKey = process.env.NEXT_PUBLIC_UE_KEY;
  const headerScript = get(pageProps, "scriptData.headerScript");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("globalLoader");
      if (loader) loader.style.display = "none";
    }
  }, []);

  useEffect(() => {
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);

  return (
    <CitiesStateProvider>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
      </Head>
      {headerScript && <Head>{parse(pageProps.scriptData.headerScript)}</Head>}
      {!!posId && !!posKey && (
        <Script
          id="payu-script"
          strategy="beforeInteractive"
          src="https://static.payu.com/res/v2/widget-products-installments.min.js"
        />
      )}
      {!!ueApiKey && (
        <Script
          id="ue-script"
          strategy="afterInteractive"
          src="https://coderslab.user.com/widget.js"
        />
      )}

      {Component.getLayout ? (
        Component.getLayout(pageProps, <Component {...pageProps} />)
      ) : (
        <Component {...pageProps} />
      )}
    </CitiesStateProvider>
  );
}
export default appWithTranslation(MyApp);

import React, { createContext, useContext, useState } from "react";
import { City } from "../types/City";

type CitiesContextState = {
  cities: Array<City>;
  update: (state: Array<City>) => void;
};

const initialCitiesContextState: CitiesContextState = {
  cities: [],
  update: () => [],
};

const CitiesContext = createContext<CitiesContextState>(
  initialCitiesContextState
);

export const useCitiesState = (): CitiesContextState =>
  useContext(CitiesContext);

export const CitiesStateProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }): React.ReactElement => {
  const [citiesState, setCitiesState] = useState(
    initialCitiesContextState.cities
  );

  return (
    <CitiesContext.Provider
      value={{
        cities: citiesState,
        update: setCitiesState,
      }}
    >
      {children}
    </CitiesContext.Provider>
  );
};

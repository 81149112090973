import { snakeCase } from "lodash";

export const pageview = (url: string): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    });
  }
};

export const collectingLeadsSend = (): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "FormEvent",
      eventCategory: "Form",
      eventAction: "Send",
    });
  }
};

export const collectingLeadsSendWithData = (
  anchorId: string,
  email: string,
  city: string,
  phone: string,
  url: string
): void => {
  const timeStamp = `${anchorId}_${new Date().getTime()}`;
  const cityLowerCase = snakeCase(city);

  if (window && window.dataLayer) {
    window.dataLayer.push({
      PageType: "TransactionPage",
      event: "cl_lead",
      eventCategory: "lead_b2c",
      eventValue: "1000.00",
      ProductTransactionProducts: "all",
      course_technology: "all",
      eventAction: anchorId,
      email: email,
      TransactionID: timeStamp,
      cl_form: anchorId,
      cl_region: cityLowerCase,
      phone_number: phone,
      eventLabel: url,
      page_url: url,
    });
  }
};

export const optimizeActivateEvent = (): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "optimize.activate",
    });
  }
};
